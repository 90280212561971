<template>
    <div class="field mb0">
        <label>{{ album.label }}</label>

        <div
            v-for="(row, i) in model"
            :key="`r-item-${row.id}`"
            class="album-row"
        >
            <a
                target="_blank"
                rel="noopener noreferrer"
                :href="row._link"
                class="file-preview"
            >
                <img
                    v-if="isImage(row)"
                    class="img"
                    :src="row._image"
                >
                <img
                    v-else
                    class="icon"
                    :src="uploadedFileIcon(row)"
                >
            </a>

            <div class="details">
                <y-form-field
                    v-if="album.title_input"
                    v-model="model[i].title"
                    type="text"
                    name="title"
                    no-label
                    :placeholder="$t('fields.title')"
                    class="mb5"
                    size="sm"
                />
                <y-form-field
                    v-if="album.description_input"
                    v-model="model[i].description"
                    type="textarea"
                    rows="2"
                    name="description"
                    no-label
                    :placeholder="$t('fields.description')"
                    class="mb5"
                    size="sm"
                />
                <y-form-field
                    v-if="album.hyperlink_input"
                    v-model="model[i].hyperlink"
                    type="text"
                    name="link"
                    no-label
                    :placeholder="$t('fields.link')"
                    class="mb0"
                    size="sm"
                />
            </div>

            <span
                class="remove"
                @click.stop.prevent="removeFile(row.id)"
            >
                <img
                    src="@/assets/images/remove.svg"
                    width="20"
                    alt=""
                >
            </span>
        </div>

        <y-form-uploader
            v-if="show"
            :key="`${album.name}_uploader_${keyHelper}`"
            ref="uploader"
            class="mt5 mb0 row-uploader"
            no-label
            simple
            :name="album.name"
            :max-file-size="album.max_volume_files"
            :accepted-files="album.allowed_extensions"
            :max-files="maxFiles"
            :payload="album.payload"
            :options="album.options"
            @success="setRowItem($event)"
            @complete="queueComplete"
        />
    </div>
</template>

<script>
    import { generateId } from '@nodes/helpers/string';
    import imageIcon from '@/assets/images/file-type/image.svg';
    import videoIcon from '@/assets/images/file-type/video.svg';
    import docIcon from '@/assets/images/file-type/document.svg';
    import fileIcon from '@/assets/images/file-type/file.svg';

    export default {

        name: 'DetailedAlbum',

        components: {
            YFormField   : () => import('@deps/form/FormField'),
            YFormUploader: () => import('@deps/form/elements/Uploader'),
        },

        props: {
            album: {
                type    : Object,
                required: true,
            },
        },

        /**
         * @inheritDoc
         */
        data() {
            return {
                model    : [],
                temp     : this.value || [],
                show     : true,
                keyHelper: generateId(),

                icon: {
                    image   : imageIcon,
                    video   : videoIcon,
                    document: docIcon,
                    file    : fileIcon,
                },
            };
        },

        computed: {

            /**
             * Calculate max files limit for uploader
             */
            maxFiles() {
                return this.album.max_number_files - this.model.length;
            },

        },

        watch: {
            /**
             * Watch value set new model
             */
            value: {
                // eslint-disable-next-line require-jsdoc
                handler(val) {
                    this.$set(this, 'model', val);
                },
                deep: true,
            },

            /**
             * Watch value set new model
             */
            model: {
                // eslint-disable-next-line require-jsdoc
                handler() {
                    this.$emit('input', this.model);
                },
                deep: true,
            },
        },

        /**
         * @inheritDoc
         */
        created() {
            if (this.album.files && this.album.files.length) {
                this.model = this.album.files.map((file) => ({
                    id         : file.id,
                    _image     : file.link,
                    _link      : file.link,
                    title      : file.title,
                    description: file.description,
                    hyperlink  : file.hyperlink,
                    _mime_type : file.mime_type,
                }));
            }
            this.handleShow();
        },

        methods: {

            /**
             * Get array of values for simple album
             *
             * @param file
             */
            setRowItem(file) {
                if (file && file.id && this.model.length < this.album.max_number_files) {
                    this.model.push({
                        id        : file.id,
                        _image    : file.link,
                        _link     : file.link,
                        _mime_type: file.mime_type,
                    });
                }
            },

            /**
             * Remove file from album
             *
             * @param id
             */
            removeFile(id) {
                const index = this.model.findIndex((el) => el.id === id);
                if (index > -1) {
                    this.model.splice(index, 1);
                }
                this.queueComplete();
            },

            /**
             * @inheritDoc
             */
            queueComplete() {
                this.handleShow();
                this.keyHelper = generateId();
            },

            /**
             * Handle display of uploader
             */
            handleShow() {
                this.show = this.model.length < this.album.max_number_files;
            },

            /**
             * Check file is image
             *
             * @param file
             */
            isImage(file) {
                return this.get(file, '_mime_type', '').includes('image');
            },

            /**
             * Get proper icon for each file
             *
             * @param file
             */
            uploadedFileIcon(file) {
                const mime = file._mime_type;

                if (!mime) {
                    return this.icon.file;
                }

                if (mime.includes('image')) {
                    return this.icon.image;
                }

                if (mime.includes('video')) {
                    return this.icon.video;
                }

                if (mime.includes('pdf')) {
                    return this.icon.document;
                }

                return this.icon.file;
            },

        },

    };
</script>
